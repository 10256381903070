import TextStyle from "@tiptap/extension-text-style";

const ClassNameExtension = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) return {};

          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      toggleClass:
        (className) =>
        ({ commands }) =>
          commands.toggleMark("textStyle", { class: className }, { extendEmptyMarkRange: true }),
    };
  },
});

export default ClassNameExtension;
