import ChatIcon from "@mui/icons-material/Chat";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import WorkIcon from "@mui/icons-material/Work";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { browserRoutes as sharedBrowserRoutes } from "config";
import prospectIconInverted from "images/icons/prospect100icon-inverted.svg";
import prospectIcon from "images/icons/prospect100icon.png";
import { Navigate, Outlet } from "react-router-dom";

import { ProtectedRoute } from "features/authentication";
import { SubmissionModal } from "features/submission";

import { Avatar } from "ui";

import { generatePath, redirectDocument } from "utils/helpers";

import About from "./pages/About";
import Brief from "./pages/Brief";
import BriefLanding from "./pages/BriefLanding";
import Briefs from "./pages/Briefs";
import EnterBrief from "./pages/EnterBrief";
import FeedbackHub from "./pages/FeedbackHub";
import Leaderboard from "./pages/Leaderboard";
import Organisation from "./pages/Organisation";
import UserEdit from "./pages/UserEdit";
import UserProfile from "./pages/UserProfile";
import Voting from "./pages/Voting";
import routes from "./routes";

export const redirects = {
  "/amfar": { to: routes.organisation, params: { id: 4 } },
  "/zellerfeld": { to: routes.organisation, params: { id: 9 } },
  "/lp": { to: routes.organisation, params: { id: 12 } },
  "/ketnipz": { to: routes.organisation, params: { id: 3 } },
  "/illenium": { to: routes.brief, params: { id: 37 } },
  "/duranduran": { to: routes.brief, params: { id: 42 } },
};

export const browserRoutes = {
  ...sharedBrowserRoutes,
  home: {
    path: routes.home,
    element: <Navigate to={routes.briefs} replace />,
    index: true,
  },
  get dashboardRedirect() {
    return {
      path: routes.dashboard,
      loader: ({ params }) => redirectDocument(routes.home, params),
    };
  },
  briefs: {
    path: routes.briefs,
    element: <Briefs />,
  },
  brief: {
    path: routes.brief,
    element: (
      <>
        <Brief />
        <Outlet />
      </>
    ),
    children: {
      submission: {
        path: routes.briefSubmission,
        element: <SubmissionModal show />,
      },
    },
  },
  briefLanding: {
    path: routes.briefLanding,
    element: <BriefLanding />,
  },
  voting: {
    path: routes.voting,
    element: (
      <>
        <Voting />
        <Outlet />
      </>
    ),
    children: {
      submission: {
        path: routes.votingSubmission,
        element: <SubmissionModal show />,
      },
    },
  },
  about: {
    path: routes.about,
    element: <About />,
  },
  organisation: {
    path: routes.organisation,
    element: <Organisation />,
  },
  userProfile: {
    path: routes.userProfile,
    element: (
      <>
        <UserProfile />
        <Outlet />
      </>
    ),
    children: {
      submission: {
        path: routes.userProfileSubmission,
        element: <SubmissionModal show />,
      },
    },
  },
  userProfileEdit: {
    path: routes.userProfileEdit,
    element: <ProtectedRoute component={UserEdit} />,
  },
  leaderboard: {
    path: routes.leaderboard,
    element: <Leaderboard />,
  },
  enterBrief: {
    path: routes.enterBrief,
    element: <ProtectedRoute component={EnterBrief} />,
  },
  feedbackHub: {
    path: routes.feedbackHub,
    element: (
      <>
        <ProtectedRoute component={FeedbackHub} />
        <Outlet />
      </>
    ),
    children: {
      submission: {
        path: routes.feedbackHubSubmission,
        element: <SubmissionModal show />,
      },
    },
  },
  redirectOrg: {
    path: routes.redirectOrg,
    loader: ({ params }) => redirectDocument(process.env.REACT_APP_ORG_URL, params),
  },
  get briefSubmissionRedirect() {
    return {
      path: "/voting/*",
      loader: ({ params }) => redirectDocument("/briefs", params),
    };
  },
  // We need this for existing submission routes (such as those shared through links) that will still work
  get submission() {
    const id = window.location.pathname.split("/").slice(-1)[0];
    return {
      path: "/submission/:id",
      loader: ({ params }) =>
        redirectDocument(generatePath(routes.votingSubmission, { submissionId: id }), params),
    };
  },
  // Add our redirects (e.g. /zellerfeld and /amfar)
  ...Object.fromEntries(
    Object.entries(redirects).map(([key, { to, params }]) => [
      key,
      {
        path: key,
        loader: ({ params: routeParams }) =>
          redirectDocument(generatePath(to, params), routeParams),
      },
    ]),
  ),
};

const navbarRoutes = {
  briefs: ({ navigate }) => ({
    name: "Briefs",
    matchPath: routes.briefs,
    onClick: () => navigate(routes.briefs),
    icon: WorkOutlineOutlinedIcon,
    selectedIcon: WorkIcon,
  }),
  voting: ({ navigate }) => ({
    name: "Voting",
    matchPath: routes.voting,
    onClick: () => navigate(routes.voting),
    icon: FavoriteBorderOutlinedIcon,
    selectedIcon: FavoriteIcon,
  }),
  search: ({ handleToggleOffcanvas }) => ({
    name: "Search",
    onClick: () => handleToggleOffcanvas({ page: "search", isProtectedAction: false }),
    icon: SearchIcon,
    selectedIcon: SearchIcon,
  }),
  leaderboard: ({ navigate }) => ({
    name: "Leaderboard",
    matchPath: routes.leaderboard,
    onClick: () => navigate(routes.leaderboard),
    icon: LeaderboardOutlinedIcon,
    selectedIcon: LeaderboardIcon,
  }),
  activity: ({ handleToggleOffcanvas }) => ({
    name: "Activity",
    onClick: () => handleToggleOffcanvas({ page: "activity", isProtectedAction: true }),
    icon: NotificationsNoneOutlinedIcon,
    selectedIcon: NotificationsIcon,
  }),
  account: ({ user, handleToggleOffcanvas }) => ({
    name: "Account",
    ...(user.username
      ? { matchPath: generatePath(routes.userProfile, { username: user.username }) }
      : {}),
    onClick: () => handleToggleOffcanvas({ page: "account", isProtectedAction: true }),
    icon: ({ className = "", ...props }) => {
      if (user.doesSessionExist) {
        return (
          <div className={className}>
            <Avatar
              src={user?.icon}
              name={user.username}
              data-testid="account-button"
              size="sm"
              className="!size-6 md:!size-8"
              {...props}
            />
          </div>
        );
      }
      return (
        <PersonOutlineOutlinedIcon
          data-testid="show-auth-button"
          className={className}
          {...props}
        />
      );
    },
    selectedIcon: ({ className = "", ...props }) => (
      <div className={className}>
        <Avatar
          src={user?.icon}
          name={user.username}
          data-testid="account-button"
          size="sm"
          className="!size-6 md-!size-8"
          isBordered
          {...props}
        />
      </div>
    ),
  }),
  feedback: ({ navigate }) => ({
    name: "Feedback",
    matchPath: routes.feedbackHub,
    onClick: () => navigate(routes.feedbackHub),
    icon: ChatOutlinedIcon,
    selectedIcon: ChatIcon,
  }),
  settings: ({ navigate }) => ({
    name: "Settings",
    onClick: () => navigate(`${routes.userProfileEdit}#marketing`),
    icon: SettingsOutlinedIcon,
    selectedIcon: SettingsIcon,
  }),
  orgDashboard: {
    name: "Org Dashboard",
    onClick: () =>
      window.open(
        generatePath(routes.redirectOrg, {
          "*": `?${new URLSearchParams({ redirectFromPath: window.location.pathname }).toString()}`,
        }),
        "_blank",
      ),
    icon: SpaceDashboardOutlinedIcon,
    selectedIcon: SpaceDashboardIcon,
  },
  reportProblem: ({ showError }) => ({
    name: "Report Problem",
    onClick: showError,
    icon: ErrorOutlineOutlinedIcon,
    selectedIcon: ErrorIcon,
  }),
  appearance: ({ theme, applyTheme }) => ({
    name: theme === "dark" ? "Light Mode" : "Dark Mode",
    onClick: () => applyTheme(theme === "dark" ? "light" : "dark"),
    icon: theme === "dark" ? LightModeOutlinedIcon : DarkModeOutlinedIcon,
    selectedIcon: theme === "dark" ? LightModeIcon : DarkModeIcon,
  }),
};

const config = {
  isOrg: false,
  redirectDefault: "/*",
  redirectOrg: routes.redirectOrg,
  redirectHome: "/",
  navbar: {
    collapse: "md",
    allowSearch: true,
    icon: {
      light: prospectIcon,
      dark: prospectIconInverted,
    },
    routes: ({ user, ...props }) => ({
      mobileRoutes: [
        navbarRoutes.briefs(props),
        navbarRoutes.voting(props),
        navbarRoutes.leaderboard(props),
        navbarRoutes.account({ user, ...props }),
      ],
      desktopRoutes: [
        navbarRoutes.briefs(props),
        navbarRoutes.voting(props),
        navbarRoutes.leaderboard(props),
      ],
      desktopIconRoutes: [navbarRoutes.search(props), navbarRoutes.account({ user, ...props })],
      offcanvasRoutes: [
        navbarRoutes.search(props),
        navbarRoutes.feedback(props),
        navbarRoutes.settings(props),
        ...(!user.organisation || !user.organisation.id ? [] : [navbarRoutes.orgDashboard]),
        navbarRoutes.appearance(props),
        navbarRoutes.reportProblem(props),
      ],
    }),
  },
};

export default config;
