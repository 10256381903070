import { useContext, useState } from "react";

import { useBrief } from "features/brief";
import { UserContext } from "features/user";

// Create a preview submission from form data
export default function usePreviewSubmission({ briefId, getValues }) {
  const { user } = useContext(UserContext);
  const { data: brief } = useBrief(briefId);
  const [submission, setSubmission] = useState({});

  const handleCreatePreviewSubmission = () => {
    const formData = { ...getValues() };
    formData.id = 0;
    formData.published = false;
    formData.images = formData.images.map(({ order_id: orderId, file_id: fileId, ...image }) => ({
      order_id: orderId,
      image: { original: { id: fileId, ...image } },
    }));
    formData.brief = brief;
    formData.user = user;

    setSubmission(formData);
  };

  const handleClearPreviewSubmission = () => setSubmission({});

  return {
    submission,
    handleClearPreviewSubmission,
    handleCreatePreviewSubmission,
  };
}
