import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";

import { Button, Dropdown } from "ui";

import useKey from "hooks/useKey";
import { isIos } from "utils/helpers";

const shortcuts = (ios = false) => ({
  draft: {
    text: ios ? "⌘+S" : "Ctrl+S",
    shortcut: ios ? ["cmd", "s"] : ["ctrl", "s"],
  },
  publish: {
    text: ios ? "⌘+Shift+S" : "Ctrl+Shift+S",
    shortcut: ios ? ["cmd", "shift", "s"] : ["ctrl", "shift", "s"],
  },
  preview: {
    text: ios ? "⌘+Shift+P" : "Ctrl+Shift+P",
    shortcut: ios ? ["cmd", "shift", "p"] : ["ctrl", "shift", "p"],
  },
});

// const shortcuts

export default function EnterBriefFormOptions({ handlePreview, handleSaveDraft, handlePublish }) {
  const draftShortcut = shortcuts(isIos()).draft;
  const publishShortcut = shortcuts(isIos()).publish;
  const previewShortcut = shortcuts(isIos()).preview;

  useKey(draftShortcut.shortcut, handleSaveDraft);
  useKey(publishShortcut.shortcut, handlePublish);
  useKey(previewShortcut.shortcut, handlePreview);

  return (
    <div className="fixed right-0 top-[50%]">
      <Dropdown
        classNames={{
          base: "font-roman before:bg-default-200",
          content: "border border-default-200",
        }}
      >
        <Dropdown.DropdownTrigger>
          <Button
            iconOnly
            radius="none"
            className="min-w-[auto] p-3 aspect-square shadow-xl rounded-s-xl"
            color="primary"
          >
            <SettingsIcon />
          </Button>
        </Dropdown.DropdownTrigger>

        <Dropdown.DropdownMenu
          itemClasses={{
            base: "rounded-xl py-2 px-3",
          }}
        >
          <Dropdown.DropdownItem
            startContent={<PreviewIcon />}
            key="preview"
            description="Preview your submission"
            onClick={handlePreview}
            shortcut={previewShortcut.text}
          >
            Preview
          </Dropdown.DropdownItem>
          <Dropdown.DropdownItem
            startContent={<EditIcon />}
            key="draft"
            description="Carry on your submission later"
            onClick={handleSaveDraft}
            shortcut={draftShortcut.text}
          >
            Save Draft
          </Dropdown.DropdownItem>
          <Dropdown.DropdownItem
            startContent={<PublishIcon />}
            key="publish"
            description="Finalise your submission"
            onClick={handlePublish}
            shortcut={publishShortcut.text}
          >
            Publish
          </Dropdown.DropdownItem>
        </Dropdown.DropdownMenu>
      </Dropdown>
    </div>
  );
}
EnterBriefFormOptions.propTypes = {
  handlePreview: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
};
