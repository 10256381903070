import { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { NavigationConfigContext } from "features/navigation";
import { useSubmission } from "features/submission/api";
import SubmissionSpotlightSelectButton from "features/submission/components/button/SubmissionSpotlightSelectButton";
import SubmissionVoteButton from "features/submission/components/button/SubmissionVoteButton";
import SubmissionWinnerSelectButton from "features/submission/components/button/SubmissionWinnerSelectButton";
import { UserBadgeList } from "features/user";

import { Avatar, ButtonGroup, Chip, Skeleton } from "ui";

import { generatePath } from "utils/helpers";

import routes from "default/routes";

function SubmissionHeaderLoading() {
  return (
    <div className="flex justify-between items-center pb-8 mt-12">
      <div className="flex items-center w-1/2">
        <Skeleton className=" aspect-square !size-16 rounded-full" />

        <div className="mx-4 w-1/2">
          <Skeleton className="w-full p-2 rounded h-1/2" />
          <Skeleton className="w-1/2 p-2 mt-2 rounded h-1/2" />
        </div>
      </div>

      <div className="w-1/2 flex justify-end">
        <Skeleton className="w-1/4 p-6 rounded-full h-full" />
      </div>
    </div>
  );
}

export default function SubmissionHeader({ submissionId }) {
  const { config } = useContext(NavigationConfigContext);
  const {
    data: submission,
    showSelectWinner,
    showSelectSpotlight,
    showVoteButton,
  } = useSubmission(submissionId);

  return (
    <div className="flex flex-col md:flex-row justify-between md:items-center py-8 text-primary-foreground px-4 md:px-0 gap-4">
      <div className="flex items-center w-11/12 md:w-full">
        <Avatar src={submission.user?.icon} name={submission.user.username} size="lg" />
        <div className="mx-4">
          <div className="flex items-center gap-2">
            <span className="font-roman line-clamp-1 me-8 sm:me-0">
              {submission.title || `"${submission.brief.title}" Submission`}
            </span>

            {!submission.published && (
              <Chip
                classNames={{
                  base: "bg-purple text-primary-foreground",
                  dot: "bg-content1",
                }}
                variant="dot"
              >
                Draft
              </Chip>
            )}

            {submission.type === "proposal" && (
              <Chip
                classNames={{
                  base: "bg-purple text-primary-foreground",
                  dot: "bg-content1",
                }}
                variant="dot"
              >
                Proposal
              </Chip>
            )}
          </div>

          <Link
            className="inline-flex items-center"
            to={generatePath(config.redirectDefault, {
              wildcard: generatePath(routes.userProfile, {
                username: submission.user?.username || "",
              }),
            })}
          >
            <span className="inline-block old-transition-underline uppercase">
              {submission.user?.username}
            </span>
            <UserBadgeList className="!size-4 ms-2" badges={submission.user.badges} />
          </Link>
        </div>
      </div>

      {(showSelectSpotlight || showSelectWinner) && (
        <ButtonGroup fullWidth>
          {showSelectSpotlight && <SubmissionSpotlightSelectButton submissionId={submissionId} />}
          {showSelectWinner && <SubmissionWinnerSelectButton submissionId={submissionId} />}
        </ButtonGroup>
      )}

      {showVoteButton && (
        <div className="hidden md:block">
          <SubmissionVoteButton submissionId={submissionId} trackingLocation="submission header" />
        </div>
      )}
    </div>
  );
}
SubmissionHeader.propTypes = {
  submissionId: PropTypes.number.isRequired,
};
SubmissionHeader.Loading = SubmissionHeaderLoading;
