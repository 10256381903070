import { useEffect, useRef } from "react";

export default function useKey(key, cb) {
  const callback = useRef(cb);

  useEffect(() => {
    callback.current = cb;
  }, [cb]);

  useEffect(() => {
    let pressed = [];

    const handle = (e) => {
      if (e.key === "Control" && e.ctrlKey) pressed.push("ctrl");
      else if (e.key === "Meta" && e.metaKey) pressed.push("cmd");
      else pressed.push(e.key.toLowerCase());

      if (JSON.stringify(pressed) === JSON.stringify(key)) {
        e.preventDefault();
        callback.current(e);
        pressed = [];
      }
    };
    const reset = () => {
      pressed = [];
    };

    document.addEventListener("keydown", handle);
    document.addEventListener("keyup", reset);

    return () => {
      document.removeEventListener("keydown", handle);
      document.removeEventListener("keyup", reset);
    };
  }, [key]);
}
