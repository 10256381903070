import { useContext } from "react";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { NavigationConfigContext } from "features/navigation";
import { useSubmission } from "features/submission/api";
import StickyActionButton from "features/submission/components/button/StickyActionButton";
import StickyActionShareButton from "features/submission/components/button/StickyActionShareButton";
import SubmissionStickyActionVoteButton from "features/submission/components/button/SubmissionStickyActionVoteButton";

import { generatePath } from "utils/helpers";

import routes from "default/routes";

const trackingLocation = "submission actions";

export default function SubmissionActions({ submissionId, scrollToFeedback }) {
  const { config } = useContext(NavigationConfigContext);
  const { data: submission, showVoteButton } = useSubmission(submissionId);
  const navigate = useNavigate();

  const sharingURLPath = generatePath(routes.userProfileSubmission, {
    username: submission.user.username,
    submissionId: submission.id,
  });

  return (
    <div className="w-14 -mr-14 hidden md:block sticky top-32 h-full text-primary-foreground">
      <div className="ml-4 ">
        {showVoteButton && (
          <SubmissionStickyActionVoteButton
            submissionId={submissionId}
            trackingName="vote"
            trackingLocation={trackingLocation}
            color="success"
          />
        )}

        <StickyActionButton
          onClick={scrollToFeedback}
          title="Feedback"
          tooltip="Join the conversation!"
          icon={ForumIcon}
          trackingName="view feedback"
          trackingLocation={trackingLocation}
          variant="faded"
          className="border-none p-1"
        />

        <StickyActionButton
          onClick={() =>
            navigate(
              `${generatePath(config.redirectDefault, { wildcard: generatePath(routes.brief, { id: submission.brief.id }) })}?tab=details`,
            )
          }
          title="View Brief"
          tooltip={submission.brief.title}
          icon={FeedOutlinedIcon}
          trackingName="view brief"
          trackingLocation={trackingLocation}
          variant="faded"
          className="border-none"
        />

        {submission.published && (
          <StickyActionShareButton
            title={`${submission.title} - entry to ${submission.brief.title} - by ${submission.user.username}`}
            url={sharingURLPath}
            trackingLocation={trackingLocation}
            variant="faded"
            className="border-none"
            shareButtonProps={{
              color: "primary",
            }}
          />
        )}
      </div>
    </div>
  );
}
SubmissionActions.propTypes = {
  submissionId: PropTypes.number.isRequired,
  scrollToFeedback: PropTypes.func.isRequired,
};
