import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PropTypes from "prop-types";

import { useSubmission } from "features/submission/api";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";

import { Button } from "ui";

export default function SubmissionWinnerSelectButton({ submissionId }) {
  const [loading, setLoading] = useState(false);
  const { data: submission } = useSubmission(submissionId);
  const { handleSelectWinner, handleDeselectWinner } = useSubmissionWithActions(submissionId);
  const isWinner = submission.is_winner ?? false;
  const text = submission.type === "submission" ? "Make Winner" : "Accept";
  const undoText = submission.type === "submission" ? "Winner" : "Accepted";

  const handleSelectOrDeselectWinner = async () => {
    setLoading(true);
    if (isWinner) await handleDeselectWinner();
    else await handleSelectWinner();
    setLoading(false);
  };

  return (
    <Button
      color="primary"
      endContent={isWinner ? <CancelIcon /> : <EmojiEventsIcon />}
      size="lg"
      isLoading={loading}
      onClick={handleSelectOrDeselectWinner}
    >
      {isWinner ? undoText : text}
    </Button>
  );
}
SubmissionWinnerSelectButton.propTypes = {
  submissionId: PropTypes.number.isRequired,
};
