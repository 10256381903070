import { useEffect, useMemo, useState } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";

import Mixpanel from "adapters/mixpanel";
import Sentry from "adapters/sentry";

export default function useAuthProvider(config) {
  const sessionContext = useSessionContext();
  const [auth, setAuth] = useState({
    show: false,
    options: {},
  });

  const logout = async () => {
    await signOut();
    Mixpanel.reset();
    Sentry.setUser(null);
    window.location.href = config.redirectHome;
  };

  const showAuth = (options) => {
    // Redirect back to the page they were on
    const params = new URLSearchParams({
      redirectToPath: options?.redirectToPath || window.location.pathname,
    });
    window.history.replaceState("", "", `${window.location.pathname}?${params}`);

    setAuth({
      ...auth,
      show: true,
      options,
    });
  };

  const hideAuth = () => setAuth({ ...auth, show: false });

  useEffect(() => {
    // We don't want to show the modal until AFTER the return action
    if (localStorage.getItem("return-action")) return;

    const search = new URLSearchParams(window.location.search);
    if (Boolean(search.get("completeOnboarding")) === true && sessionContext.doesSessionExist)
      showAuth({ onboarding: true });
  }, [localStorage, sessionContext]);

  const authContextProviderValue = useMemo(
    () => ({ showAuth, hideAuth, logout }),
    [showAuth, hideAuth, logout],
  );

  return { auth, authContextProviderValue };
}
