import { isIos } from "./helpers";

export default function configureOSFont() {
  if (isIos()) {
    const newStyle = document.createElement("style");
    newStyle.appendChild(
      document.createTextNode(
        "html, body {font-family: 'Helvetica-Neue-LT-Pro-Bold-Condensed-IOS', sans-serif;}",
      ),
    );
    document.head.appendChild(newStyle);
  }
}
