import { useContext } from "react";

import { briefQueries } from "features/brief";
import { NavigationConfigContext } from "features/navigation";

import { client, useMany, useMutation, useOne, useQuery, useQueryClient } from "api";

const baseURL = "submissions/";

const submissionQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
  voters: (id) => [
    {
      url: baseURL,
      view: "detail",
      id: id == null ? id : String(id),
      type: "voters",
    },
  ],
};

const useSubmissions = (params) => useMany({ queryKey: submissionQueries.list(params) });
const useSubmission = (id) => {
  const {
    config: { isOrg },
  } = useContext(NavigationConfigContext);
  const { data: submissionData, ...rest } = useOne({ queryKey: submissionQueries.detail(id) });
  const submission = submissionData ?? {};

  return {
    data: submission,
    showFeedback: !isOrg && submission?.published,
    showVoteButton: !isOrg && submission?.published,
    showSelectWinner: isOrg && submission?.published,
    showSelectSpotlight:
      isOrg &&
      submission?.published &&
      submission.brief?.type !== "private" &&
      submission.brief?.status !== "submitting",
    ...rest,
  };
};

const useSubmissionVoters = (id) =>
  useQuery({
    queryKey: submissionQueries.voters(id),
    queryFn: async ({ queryKey: [{ url }] }) => {
      const response = await client.get(`${url}${id}/voters/`);
      return response.data;
    },
  });

const useSubmissionReport = (id) =>
  useMutation({
    mutationFn: (data) => client.post(`${baseURL}${id}/report`, data),
  });

const useSubmissionMutations = () => {
  const queryClient = useQueryClient();
  return {
    createSubmission: useMutation({
      mutationFn: ({ briefId, data }) =>
        client.post(`briefs/${briefId}/submissions`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      onSuccess: ({ data: submission }) => {
        queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
        queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
        queryClient.invalidateQueries({
          queryKey: briefQueries.detail(submission.brief.id),
        });
      },
    }),
    updateSubmission: useMutation({
      mutationFn: ({ id, data }) =>
        client.patch(`submissions/${id}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      onSuccess: ({ data: submission }) => {
        queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
        queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
        queryClient.invalidateQueries({
          queryKey: briefQueries.detail(submission.brief.id),
        });
      },
    }),
  };
};

export {
  useSubmission,
  useSubmissions,
  useSubmissionVoters,
  useSubmissionMutations,
  useSubmissionReport,
  submissionQueries,
};
