import { useContext } from "react";
import Ellipsis from "images/icons/lucide-icons/Ellipsis";
import Eye from "images/icons/lucide-icons/Eye";
import EyeOff from "images/icons/lucide-icons/EyeOff";
import Trash2 from "images/icons/lucide-icons/Trash2";
import PropTypes from "prop-types";

// import MessageCircleWarning from 'images/icons/lucide-icons/MessageCircleWarning';

import feedbackPropTypes from "features/feedback/feedbackSchema";
import { UserContext } from "features/user";

import { Dropdown } from "ui";

function FeedbackDropDown({ comment, isOwnSubmission, handleDeleteComment, handleHideComment }) {
  const { user } = useContext(UserContext);
  const isCommentAuthor = comment.user.id === user.id;
  if (!isCommentAuthor && !isOwnSubmission) return null;

  return (
    <Dropdown
      classNames={{
        base: "font-roman before:bg-default-200", // change arrow background
        content: "border border-default-200",
      }}
    >
      <Dropdown.DropdownTrigger>
        <div className="relative w-8 h-8">
          <Ellipsis />
        </div>
      </Dropdown.DropdownTrigger>
      <Dropdown.DropdownMenu
        aria-label="Comment Actions"
        itemClasses={{
          base: ["rounded-xl py-2 px-3"],
          list: ["normal-case"],
        }}
      >
        {isOwnSubmission && (
          <Dropdown.DropdownItem
            key="ToggleHide"
            description={
              comment.hidden_at ? "Make this comment public" : "Remove comment from public view"
            }
            startContent={
              comment.hidden_at ? <Eye className="mr-1" /> : <EyeOff className="mr-1" />
            }
            onClick={handleHideComment}
          >
            {comment.hidden_at ? "Unhide" : "Hide"}
          </Dropdown.DropdownItem>
        )}
        {/* <DropdownItem
                    key="Report"
                    description="Report inappropriate content"
                    startContent={<MessageCircleWarning className="mr-1" />}
                >
                    Report
                </DropdownItem> */}
        {isCommentAuthor && (
          <Dropdown.DropdownItem
            key="Delete"
            description="Delete your comment"
            startContent={<Trash2 className="mr-1" />}
            onClick={handleDeleteComment}
          >
            Delete
          </Dropdown.DropdownItem>
        )}
      </Dropdown.DropdownMenu>
    </Dropdown>
  );
}
FeedbackDropDown.propTypes = {
  isOwnSubmission: PropTypes.bool.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  handleHideComment: PropTypes.func.isRequired,
  comment: feedbackPropTypes.isRequired,
  user: PropTypes.number.isRequired,
};

export default FeedbackDropDown;
