import BackupIcon from "@mui/icons-material/Backup";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import { Button, Progress } from "ui";

export default function Dropzone({
  acceptedFileTypes,
  handleOpenFileDialog,
  handleCancelFileUpload,
  progress = 0,
  isDragReject = false,
  isInvalid = false,
}) {
  if (progress > 0 && progress < 100) {
    return (
      <div className="p-6 flex justify-between items-center gap-6 border border-dashed border-2 border-foreground rounded-3xl bg-content2">
        <Progress value={progress} />

        <Button
          color="danger"
          variant="flat"
          onClick={handleCancelFileUpload}
          trackingName="cancel file upload"
          size="sm"
        >
          Cancel
        </Button>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        "flex flex-col items-center text-center p-6 border border-dashed border-2 border-foreground transition-colors duration-300 ease-in-out relative bg-content2 hover:bg-focus rounded-3xl md:p-12",
        (isDragReject || isInvalid) && "bg-danger-100 !text-danger border-danger",
      )}
      data-role="form-error-component"
      data-testid="dropzone"
    >
      {!isDragReject ? (
        <BackupIcon className="!size-16 mr-2 mb-1" />
      ) : (
        <ClearIcon className="!size-16 mr-2 mb-1" />
      )}

      <p
        className={twMerge(
          "w-3/4 md:w-1/2 text-primary-300",
          (isDragReject || isInvalid) && "!text-danger-400",
        )}
      >
        <span>Drag &#39;n&#39; drop files here to upload. </span>
        {acceptedFileTypes}.
      </p>

      <Button
        color="primary"
        className="absolute bottom-0 -mb-4 md:w-1/4 px-8 hover:!opacity-100 uppercase"
        variant="shadow"
        onClick={handleOpenFileDialog}
        trackingName="browse file upload"
      >
        Select Files
      </Button>
    </div>
  );
}
Dropzone.propTypes = {
  acceptedFileTypes: PropTypes.string.isRequired,
  handleOpenFileDialog: PropTypes.func.isRequired,
  handleCancelFileUpload: PropTypes.func.isRequired,
  progress: PropTypes.number,
  isDragReject: PropTypes.bool,
  isInvalid: PropTypes.bool,
};
