import DOMpurify from "dompurify";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { useBrief } from "features/brief";

import { Accordion, Alert, Button } from "ui";

import Currency from "utils/currency";

import BriefTimeline from "./BriefTimeline";

// You can change the properties and order of the brief here.
const generateSections = (briefType) => [
  { title: briefType === "challenge" ? "The Challenge" : "The Brief", attribute: "description" },
  { title: "Key Deliverables", attribute: "deliverables" },
  {
    title: "Submission Guidelines",
    attribute: "things_to_consider",
  },
  {
    title: "Selection Criteria",
    attribute: "selection_criteria",
  },
  {
    title: "Incentives",
    attribute: "other_incentives",
  },
];

const fileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  order_id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
});

function LinkRenderer({ href, className = "", rel = "", children }) {
  return (
    <a target="_blank" {...{ href, className, rel }}>
      {children}
    </a>
  );
}
LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function BriefFiles({ files }) {
  return (
    <div className="flex gap-3 flex-col mb-3">
      {files
        .sort((a, b) => a.order_id - b.order_id)
        .map((file) => (
          <a
            download
            href={file.file.url}
            className="bg-default-200 rounded-3xl p-3 flex justify-between items-center"
            key={file.id}
          >
            {file.description || `${file.type} File`}

            <Button color="success" size="sm">
              Download
            </Button>
          </a>
        ))}
    </div>
  );
}
BriefFiles.propTypes = {
  files: PropTypes.arrayOf(fileType).isRequired,
};

export default function BriefDetails({ briefId }) {
  const { data: brief } = useBrief(briefId);
  const sections = generateSections(brief.type);

  return (
    <>
      <div data-testid="brief-overview" className="relative bg-content2 rounded-3xl p-3 mb-5">
        <Accordion
          selectionMode="multiple"
          variant="splitted"
          className="gap-3 px-0"
          defaultExpandedKeys={["description"]}
        >
          {sections
            .filter((s) => brief[s.attribute])
            .map((section) => (
              <Accordion.AccordionItem
                key={section.attribute}
                title={section.title}
                classNames={{
                  base: "shadow-none bg-content2",
                  trigger: "relative",
                  heading: "mb-0",
                  title: "text-2xl",
                }}
              >
                <Markdown
                  className="old-markdown"
                  rehypePlugins={[rehypeRaw]}
                  components={{ a: LinkRenderer }}
                >
                  {DOMpurify.sanitize(brief[section.attribute])}
                </Markdown>
              </Accordion.AccordionItem>
            ))}

          {brief.files?.length > 0 && (
            <Accordion.AccordionItem
              key="files"
              title="Helpful Resources"
              classNames={{
                base: "shadow-none bg-content2",
                title: "text-2xl",
              }}
            >
              <BriefFiles files={brief.files} />
            </Accordion.AccordionItem>
          )}

          {brief.prize_money && brief.prize_money > 0 && (
            <Accordion.AccordionItem
              key="prize"
              title="Prize"
              classNames={{
                base: "shadow-none bg-content2",
                title: "text-2xl",
              }}
            >
              <div className="flex items-end gap-2 mb-3">
                <span className="text-7xl">
                  {Currency.get(brief.currency).format(brief.prize_money)}
                </span>
              </div>

              {brief.status === "completed" && (
                <Alert className="font-roman text-sm">
                  The live competition for this brief has ended, and all prize money has been given
                  to the winners.
                </Alert>
              )}
            </Accordion.AccordionItem>
          )}
        </Accordion>
      </div>
      <BriefTimeline briefId={briefId} className="sm:hidden" />
    </>
  );
}
BriefDetails.propTypes = {
  briefId: PropTypes.number.isRequired,
};
