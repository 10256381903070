import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { PortalWithState } from "react-portal";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { submissionQueries, useSubmission } from "features/submission/api";
import submissionPropTypes from "features/submission/schemas/submissionSchema";

import { Button } from "ui";

import { useQueryClient } from "api";

import SubmissionBody from "./SubmissionBody";
import SubmissionHeader from "./SubmissionHeader";

export default function SubmissionModal({
  onHide = undefined,
  submission: defaultSubmission = {},
  isOrg = false,
  show = false,
}) {
  const queryClient = useQueryClient();
  const { submissionId: paramSubmissionId } = useParams();
  const submissionId = paramSubmissionId ?? defaultSubmission.id;
  const { data: submission, isLoading: isSubmissionLoading } = useSubmission(submissionId);
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // If we want the scrollbar to be for the modal and not the page behind it we must hide the overflow for that page.
  useEffect(() => {
    if (show) document.querySelector("html")?.classList.add("overflow-hidden");

    return () => document.querySelector("html")?.classList.remove("overflow-hidden");
  }, [show]);

  useEffect(() => {
    if (paramSubmissionId !== undefined) return;

    queryClient.setQueryData(submissionQueries.detail(defaultSubmission.id), defaultSubmission);
  }, [queryClient, paramSubmissionId, defaultSubmission.id]);

  const handleCloseSubmissionModal = () => {
    if (onHide) {
      onHide();
      return;
    }

    let [targetURL] = window.location.pathname.split("submission");
    if (isOrg) [targetURL] = window.location.pathname.split("org")[1].split("submission");

    navigate(`${state?.referrer || targetURL}?${searchParams.toString()}`, {
      state: {
        scrollToTop: false,
      },
    });
  };

  return (
    <PortalWithState closeOnEsc defaultOpen={show} key={show} onClose={handleCloseSubmissionModal}>
      {({ closePortal, portal }) =>
        portal(
          <>
            <div className="z-50 backdrop-opacity-disabled w-screen h-screen fixed inset-0 bg-overlay/80" />

            <motion.div
              className="flex w-screen h-[100dvh] fixed inset-0 z-50 overflow-x-auto justify-center items-start sm:items-start overflow-y-auto [--scale-enter:100%] [--scale-exit:100%] [--slide-enter:0px] [--slide-exit:80px] sm:[--scale-enter:100%] sm:[--scale-exit:103%] sm:[--slide-enter:0px] sm:[--slide-exit:0px]"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2 }}
              onMouseDown={() => !isSubmissionLoading && closePortal()}
            >
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <section
                className="flex flex-col relative z-50 box-border outline-none mx-1 sm:mx-6 sm:my-16 rounded-large my-16 bg-transparent border-none shadow-none max-w-6xl w-full md:w-4/5 !m-0 md:!-mt-8"
                data-testid="submission-modal"
                onMouseDown={(e) => e.stopPropagation()}
                role="dialog"
              >
                <Button
                  isIconOnly
                  className="bg-content1 w-10 h-10 min-w-0 absolute md:sticky self-end right-0 top-8 mr-4 md:-mr-14"
                  onClick={() => !isSubmissionLoading && closePortal()}
                >
                  <CloseIcon className="p-1" />
                </Button>

                {!isSubmissionLoading && Object.keys(submission).length > 0 ? (
                  <>
                    <SubmissionHeader submissionId={submissionId} />
                    <SubmissionBody submissionId={submissionId} />
                  </>
                ) : (
                  <>
                    <SubmissionHeader.Loading />
                    <SubmissionBody.Loading />
                  </>
                )}
              </section>
            </motion.div>
          </>,
        )
      }
    </PortalWithState>
  );
}

SubmissionModal.propTypes = {
  onHide: PropTypes.func,
  submission: submissionPropTypes,
  isOrg: PropTypes.bool,
  show: PropTypes.bool,
};
