import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useDisclosure } from "@nextui-org/react";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
import { Element } from "react-scroll";
import { ReactSortable } from "react-sortablejs";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import Input from "forms/Input";
import { Button, Collapse, Tooltip } from "ui";

import useGcsFileUpload from "hooks/useGcsFileUpload";
import { formatBytes } from "utils/helpers";

import FileInput from "./FileInput";

const fileCard = tv({
  slots: {
    wrapper: "flex flex-col w-full mb-4",
    base: "flex items-center p-4 rounded-3xl w-full bg-content4",
    downloadButton: "!size-8 text-primary-foreground",
    editButton: "text-primary-foreground !size-5 mr-2",
    deleteButton: "!size-5 rounded-full text-primary-foreground",
    size: "font-roman text-default-400",
  },
  variants: {
    error: {
      true: {
        base: "bg-danger",
      },
    },
  },
});

function FileCard({ file, onUpdate, onDelete, error = "", className = "", classNames = {} }) {
  const fileDescriptionControlRef = useRef(null);
  const { wrapper, base, downloadButton, editButton, deleteButton, size } = fileCard({
    error: !!error,
  });

  return (
    <div className={twMerge(wrapper(), classNames.wrapper)} data-slot="wrapper">
      <div
        className={twMerge(base(), className, classNames.base)}
        onClick={() => fileDescriptionControlRef.current.focus()}
        role="button"
        tabIndex={0}
        data-testid="multi-file-card"
        data-slot="base"
      >
        <Tooltip content="Download File" placement="right">
          <a download href={file.url} onClick={(e) => e.stopPropagation()}>
            <SimCardDownloadIcon
              className={twMerge(downloadButton(), classNames.downloadButton)}
              data-slot="downloadButton"
            />
          </a>
        </Tooltip>

        <div className="w-full px-4">
          <div className="flex items-center w-full">
            <EditIcon
              className={twMerge(editButton(), classNames.editButton)}
              data-slot="editButton"
            />

            <Input
              variant="underlined"
              placeholder={file.name}
              defaultValue={file?.description || file.name}
              onValueChange={onUpdate}
              ref={fileDescriptionControlRef}
              data-testid="multi-file-card-name"
              classNames={{
                input: "!text-primary-foreground",
                inputWrapper: "border-default-400 after:bg-content1",
                innerWrapper: "pb-0",
              }}
            />
          </div>

          {file.file?.size && (
            <small
              className={twMerge(size(), classNames.size)}
              data-testid="multi-file-card-size"
              data-slot="size"
            >
              {formatBytes(file.file.size)}
            </small>
          )}
        </div>

        <CloseIcon
          className={twMerge(deleteButton(), classNames.deleteButton)}
          role="button"
          onClick={onDelete}
          data-slot="deleteButton"
        />
      </div>

      {error && (
        <span className="py-2 flex items-center text-danger">
          <ErrorOutlineIcon className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
}
FileCard.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    file: PropTypes.shape({
      size: PropTypes.number,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    wrapper: PropTypes.string,
    base: PropTypes.string,
    downloadButton: PropTypes.string,
    editButton: PropTypes.string,
    deleteButton: PropTypes.string,
    size: PropTypes.string,
  }),
};

const multiFileInput = tv({
  slots: {
    base: "pb-10",
    wrapper: "flex flex-wrap gap-3 items-center justify-between mb-2",
    label: "text-center md:text-start w-full md:w-auto mb-0",
    openButton: "w-full md:w-auto",
  },
  variants: {
    isRequired: {
      true: {
        label:
          "after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5",
      },
      false: {
        wrapper: "bg-content2 p-4 rounded-3xl",
      },
    },
  },
});

export default function ControlledMultiFileInput({
  name,
  control,
  fileType,
  isRequired = true,
  maxFiles = 1,
  label = "",
  className = "",
  classNames = {},
  fileErrors = {},
  ...fileProps
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultOpen: isRequired });
  const { handleFileUpload, handleCancelFileUpload } = useGcsFileUpload(fileType);
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name,
  });
  const { base, wrapper, label: labelClassName, openButton } = multiFileInput({ isRequired });

  const handleUpdateFileDescription = (index, description) => {
    if (description === fields[index].name) return;

    update(index, {
      ...fields[index],
      description,
    });
  };

  return (
    <>
      <div className={twMerge(wrapper(), classNames.wrapper)} data-slot="wrapper">
        {label && (
          <label
            className={twMerge(labelClassName(), classNames.label)}
            htmlFor={name}
            data-slot="label"
          >
            {label}
          </label>
        )}

        {fields.length <= 0 && !isRequired && (
          <Button
            onClick={() => (isOpen ? onClose() : onOpen())}
            color={isOpen ? "primary" : "success"}
            className={twMerge(openButton(), classNames.openButton)}
            data-slot="openButton"
          >
            {!isOpen ? "Add Optional File" : "Close"}
          </Button>
        )}
      </div>

      <Collapse isOpen={isOpen}>
        <div id="collapse">
          <ReactSortable list={fields} setList={replace} animation={200}>
            {fields.map((file, index) => (
              <div className="flex items-center" key={file.file_id || file.id}>
                <Element name={String(file.file_id)} />
                <DragIndicatorIcon />
                <FileCard
                  file={file}
                  onUpdate={(description) => handleUpdateFileDescription(index, description)}
                  onDelete={() => remove(index)}
                  error={fileErrors[file.file_id]}
                />
              </div>
            ))}
          </ReactSortable>

          {fields.length < maxFiles && (
            <FileInput
              onChange={async (file, setProgress) => {
                onOpen();
                const f = await handleFileUpload(file, setProgress);

                if (Object.keys(f).length <= 0) return;

                append({
                  // attributes for reactsortablejs
                  selected: false,
                  chosen: false,
                  filtered: false,
                  ...f,
                });
              }}
              onCancel={handleCancelFileUpload}
              className={twMerge(base(), className, classNames.base)}
              name={name}
              {...fileProps}
            />
          )}
        </div>
      </Collapse>
    </>
  );
}
ControlledMultiFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  fileType: PropTypes.oneOf(["technical", "image"]).isRequired,
  isRequired: PropTypes.bool,
  maxFiles: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
    label: PropTypes.string,
    openButton: PropTypes.string,
  }),
  fileErrors: PropTypes.shape({}),
};
