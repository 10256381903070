import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
import { Element } from "react-scroll";
import { ReactSortable } from "react-sortablejs";

import { Button, Dropdown } from "ui";

import useGcsFileUpload from "hooks/useGcsFileUpload";

import FileInput from "./FileInput";

const IMAGEMAXSIZE = 26214400;

export default function ControlledMultiImageInput({
  name,
  control,
  label = "",
  fileErrors = {},
  ...fileProps
}) {
  const { handleFileUpload, handleCancelFileUpload } = useGcsFileUpload("image");
  const { fields, append, replace, remove, swap } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {label && (
        <label
          className="mb-2 block mb-2 after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5"
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <ReactSortable
        list={fields}
        setList={replace}
        animation={200}
        className={
          fields.length > 0
            ? "rounded-3xl border border-primary border-dashed bg-content2 mb-4 overflow-hidden"
            : "hidden"
        }
      >
        {fields.length > 0 &&
          fields.map((image, index) => (
            <div
              key={image.file_id}
              className={`w-full flex border-primary ${fileErrors[image.file_id] ? "bg-danger text-primary-foreground" : ""} ${index + 1 < fields.length ? "border-b" : ""}`}
            >
              <div className="flex items-center px-1">
                <DragIndicatorIcon />
              </div>
              <div className="relative w-full">
                <Element name={String(image.file_id)} />
                <img
                  src={image.url}
                  className={`w-full ${index === 0 ? "rounded-t-3xl" : ""} ${index + 1 === fields.length ? "rounded-b-3xl" : ""}`}
                  data-testid="enter-brief-image"
                  alt={`${image.name} upload`}
                  data-role="form-error-component"
                />

                {fileErrors[image.file_id] && (
                  <span className="py-4 flex items-center">
                    <ErrorOutlineIcon className="mr-2" />
                    {fileErrors[image.file_id]?.message}
                  </span>
                )}

                <div className="absolute top-0 left-0 mt-4 ml-4">
                  <Dropdown data-testid="enter-brief-image-options-dropdown">
                    <Dropdown.DropdownTrigger onClick={(e) => e.preventDefault()}>
                      <Button
                        isIconOnly
                        color="primary"
                        data-testid="enter-brief-image-options-button"
                      >
                        <SettingsOutlinedIcon />
                      </Button>
                    </Dropdown.DropdownTrigger>

                    <Dropdown.DropdownMenu>
                      <Dropdown.DropdownSection
                        showDivider
                        classNames={{
                          group: "p-0 m-0",
                          divider: "list-none",
                        }}
                      >
                        <Dropdown.DropdownItem
                          key="up"
                          onClick={() => {
                            if (index > 0) swap(index, index - 1);
                          }}
                          endContent={<ArrowCircleUpIcon />}
                        >
                          Move Up
                        </Dropdown.DropdownItem>

                        <Dropdown.DropdownItem
                          key="down"
                          onClick={() => {
                            if (index + 1 < fields.length) swap(index, index + 1);
                          }}
                          endContent={<ArrowCircleDownIcon />}
                        >
                          Move Down
                        </Dropdown.DropdownItem>
                      </Dropdown.DropdownSection>

                      <Dropdown.DropdownItem
                        key="delete"
                        onClick={() => remove(index)}
                        endContent={<DeleteIcon />}
                        color="danger"
                        className="text-danger"
                      >
                        Delete
                      </Dropdown.DropdownItem>
                    </Dropdown.DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
      </ReactSortable>

      <FileInput
        onChange={async (file, setProgress) => {
          const f = await handleFileUpload(file, setProgress);

          if (Object.keys(f).length <= 0) return;

          append({
            // attributes for reactsortablejs
            selected: false,
            chosen: false,
            filtered: false,
            ...f,
          });
        }}
        onCancel={handleCancelFileUpload}
        maxSize={IMAGEMAXSIZE}
        name={name}
        {...fileProps}
      />
    </>
  );
}
ControlledMultiImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  fileErrors: PropTypes.shape({}),
};
