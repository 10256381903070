import { forwardRef, useState } from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CharacterCount from "@tiptap/extension-character-count";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import PropTypes from "prop-types";
import { twJoin, twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import Input from "forms/Input";
import Select from "forms/Select";
import Textarea from "forms/Textarea";
import { Button, Popover, Tooltip } from "ui";

import ClassNameExtension from "./ClassNameExtension";
import menuData from "./menuData";
import useCurrentEditor from "./useCurrentEditor";

const tipTapInput = tv({
  slots: {
    base: "bg-default-100 py-2 px-3 rounded-3xl min-h-60",
    label: "text-small mb-3 block",
  },
  variants: {
    color: {
      danger: {
        base: "!bg-danger-50 !text-danger",
      },
    },
  },
});

const MenuButton = forwardRef(({ icon: Icon, tooltip, isActive, onClick }, ref) => (
  <Tooltip content={tooltip}>
    <Button
      onClick={onClick}
      size="sm"
      variant="light"
      isIconOnly
      radius="sm"
      className={isActive && isActive() && "bg-primary text-primary-foreground"}
      ref={ref}
    >
      <Icon />
    </Button>
  </Tooltip>
));
MenuButton.defaultProps = {
  isActive: undefined,
  onClick: undefined,
};
MenuButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  tooltip: PropTypes.string.isRequired,
  isActive: PropTypes.func,
  onClick: PropTypes.func,
};

function Menu({ editor }) {
  const [url, setUrl] = useState("");
  const [showSetLink, setShowSetLink] = useState(false);
  const [showTextAlign, setShowTextAlign] = useState(false);
  const menuItems = menuData(editor);
  const { alignment, headingLevel, fontSize } = useCurrentEditor(editor);
  const AlignmentIcon = menuItems.textAlign[alignment]?.icon;
  const currentLink = editor.getAttributes("link").href;

  const setLink = () => {
    if (url === null) return;

    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // should always be https
    const parsedUrl = url.includes(":") ? new URL(url) : new URL(`https://${url}`);
    parsedUrl.protocol = "https:";

    editor.chain().focus().extendMarkRange("link").setLink({ href: parsedUrl.href }).run();
  };

  return (
    <div className="bg-content1 shadow-xl p-1 rounded-xl flex justify-between border items-center mb-4 sticky top-0 z-10">
      <div className="flex gap-1 items-center">
        {Object.keys(menuItems.buttons).map((item) => (
          <MenuButton key={item} {...menuItems.buttons[item]} />
        ))}

        <Popover placement="bottom" radius="sm" isOpen={showSetLink} onOpenChange={setShowSetLink}>
          <Popover.PopoverTrigger>
            <MenuButton
              icon={InsertLinkIcon}
              isActive={() => editor.isActive("link")}
              tooltip="Set Link"
              onClick={() => setShowSetLink(!showSetLink)}
            />
          </Popover.PopoverTrigger>

          <Popover.PopoverContent className="p-2 gap-2 items-start">
            <div className="flex gap-2 items-center">
              <Input
                startContent={<InsertLinkIcon />}
                placeholder="Enter URL"
                radius="sm"
                onValueChange={setUrl}
                defaultValue={currentLink}
                autoFocus
              />
              <Button size="sm" color="primary" radius="sm" onClick={setLink}>
                {currentLink ? "Update" : "Set"} Link
              </Button>
            </div>

            {currentLink && (
              <Button
                size="sm"
                radius="sm"
                color="danger"
                onClick={() => editor.chain().focus().extendMarkRange("link").unsetLink().run()}
              >
                Remove Link
              </Button>
            )}
          </Popover.PopoverContent>
        </Popover>

        <Popover
          placement="bottom"
          radius="sm"
          isOpen={showTextAlign}
          onOpenChange={setShowTextAlign}
        >
          <Popover.PopoverTrigger>
            <MenuButton
              icon={AlignmentIcon}
              tooltip="Text Align"
              onClick={() => setShowTextAlign(!showTextAlign)}
            />
          </Popover.PopoverTrigger>

          <Popover.PopoverContent className="flex-row gap-2 p-1">
            {Object.keys(menuItems.textAlign).map((item) => (
              <MenuButton key={item} {...menuItems.textAlign[item]} />
            ))}
          </Popover.PopoverContent>
        </Popover>

        <Select
          classNames={{
            trigger: "bg-transparent border-none shadow-none h-auto min-h-9",
            mainWrapper: "w-[4.3rem]",
            base: "w-[4.3rem]",
            popoverContent: "w-48",
          }}
          aria-label="select-heading"
          renderValue={(items) =>
            items.map((item) => <div className="font-bold text-xl flex ">{item.data[1].icon}</div>)
          }
          items={Object.entries(menuItems.headings)}
          onSelectionChange={({ currentKey }) => menuItems.headings[currentKey]?.onClick()}
          selectedKeys={[headingLevel.toString()]}
          defaultSelectedKeys={["paragraph"]}
          radius="sm"
        >
          {([key, item]) => (
            <Select.SelectItem key={key} textValue={item.name}>
              <div className="font-bold flex gap-2">
                <i>{item.icon}</i>
                <div>{item.name}</div>
              </div>
            </Select.SelectItem>
          )}
        </Select>

        <Select
          classNames={{
            trigger: "bg-transparent border-none shadow-none h-auto min-h-9",
            value: "font-bold",
            mainWrapper: "w-32",
            base: "W-32",
            popoverContent: "w-48",
          }}
          aria-label="select-fontsize"
          items={Object.entries(menuItems.fontSizes)}
          onSelectionChange={({ currentKey }) => menuItems.fontSizes[currentKey]?.onClick()}
          selectedKeys={[fontSize.toString()]}
          defaultSelectedKeys={["text-base"]}
          radius="sm"
        >
          {([key, item]) => (
            <Select.SelectItem key={key} textValue={item.name}>
              <span className={twJoin("font-bold", key)}>{item.name}</span>
            </Select.SelectItem>
          )}
        </Select>
      </div>

      <div className="flex gap-1 items-center">
        {Object.keys(menuItems.status).map((item) => (
          <MenuButton key={item} {...menuItems.status[item]} />
        ))}
      </div>
    </div>
  );
}
Menu.propTypes = {
  editor: PropTypes.shape({
    getAttributes: PropTypes.func.isRequired,
    chain: PropTypes.func.isRequired,
    isActive: PropTypes.func.isRequired,
  }).isRequired,
};

export default function TipTapInput({
  name,
  color = undefined,
  maxWords = 100,
  placeholder = "",
  label = "",
  defaultValue = "",
  isInvalid = false,
  className = "",
  classNames = {},
  ...inputProps
}) {
  const [value, setValue] = useState(defaultValue);
  const { base, label: labelClassNames } = tipTapInput({ color: isInvalid ? "danger" : color });

  const editor = useEditor({
    content: defaultValue,
    editorProps: {
      attributes: {
        class: twMerge(base(), className, classNames.base),
        "data-testid": "tiptap-input",
      },
    },
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: "pl-10",
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: "pl-10",
          },
        },
      }),
      Underline.configure(),
      Link.configure({
        defaultProtocol: "https",
        protocols: ["https"],
        HTMLAttributes: {
          class: "text-success",
        },
      }),
      ClassNameExtension.configure(),
      CharacterCount.configure(),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Placeholder.configure({
        placeholder,
      }),
    ],
    onUpdate({ editor: currentEditor }) {
      setValue(currentEditor.getHTML());
    },
  });

  if (!editor) return null;

  return (
    <div className="mb-10 relative">
      {label && (
        <label htmlFor={name} className={labelClassNames()}>
          {label}
        </label>
      )}

      <Menu editor={editor} />
      <EditorContent editor={editor} />

      <Textarea
        isReadOnly
        classNames={{
          inputWrapper: "hidden",
          description: "text-right font-local",
        }}
        id={name}
        description={maxWords && `${editor.storage.characterCount.words()}/${maxWords} words`}
        {...{ name, value, isInvalid }}
        {...inputProps}
      />
    </div>
  );
}
TipTapInput.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  maxWords: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    label: PropTypes.string,
  }),
};
